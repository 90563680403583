import React from 'react';

const Map = () => (
  <section className="my-10 text-center">
    <h2 className="text-2xl md:text-3xl font-semibold text-yellow-600">Peta Lokasi</h2>
    <p className="text-base md:text-lg text-black mt-4">Anda dapat menuju lokasi acara kami dengan bantuan peta di bawah ini:</p>
    <div className="relative h-0 overflow-hidden pb-[70%] mt-6 mx-auto border-2 border-yellow-600" style={{ maxWidth: '600px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3961.5401883956774!2d108.22354817499563!3d-6.825638293172212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwNDknMzIuMyJTIDEwOMKwMTMnMzQuMCJF!5e0!3m2!1sid!2sid!4v1719403813459!5m2!1sid!2sid"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        className="absolute top-0 left-0 w-full h-full"
      ></iframe>
    </div>
  </section>
);

export default Map;
