// LandingPage.js

import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const LandingPage = () => {
  const phoneNumber = "+6281214149548"; // Ganti dengan nomor WhatsApp yang sesuai

  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  return (
    <div className="bg-gray-300 px-5 min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
        {/* <Logo className="w-24 h-24 mx-auto mb-4" /> Ganti ukuran logo sesuai kebutuhan */}
        <h1 className="text-3xl font-bold mb-4">
          Undangan Digital untuk Setiap Momen Berharga
        </h1>
        <p className="text-gray-700 mb-6">
          Sambut momen istimewa Anda dengan undangan digital yang elegan dan
          ramah lingkungan.
        </p>
        <p className="text-gray-700 mb-6">
          Kami menyediakan layanan undangan digital untuk pernikahan, khitanan,
          tunangan, dan banyak lagi. Mulai sekarang, buat momen berharga Anda
          lebih spesial dengan undangan yang modern dan praktis!
        </p>
        <div className="flex justify-center mb-4">
          <a
            href={whatsappUrl}
            className="bg-green-500 flex space-x-2 justify-center items-center  text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out"
          >
            <FaWhatsapp size={20} /> <span>Hubungi Kami</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
