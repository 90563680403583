import React from "react";
import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Details from "./components/Details";
import Map from "./components/Map";
import HomeScreen from "./Home";
import NotFound from "./NotFound";
import LandingPage from "./landing";

const App = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/keanovan" element={<HomeScreen />} />
  </Routes>
);

export default App;
