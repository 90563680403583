import React from 'react';

const Details = () => (
  <section className="my-10 text-center">
    <h2 className="text-2xl md:text-3xl font-semibold text-yellow-600">Detail Acara</h2>
    <p className="text-base md:text-lg text-black mt-4">Khitanan putra pertama dari<br/>Bapak Ivan Saefullah dan Ibu Melly Nurmiladiyah</p>
    <p className="text-base md:text-lg text-black">----------------</p>
    <p className="text-base md:text-lg text-black font-bold">Hari/Tanggal: Jumat, 05 Juli 2024</p>
    <p className="text-base md:text-lg text-black font-bold">Waktu: 08:00 WIB s.d selesai</p>
    <p className="text-base md:text-lg text-black">Tempat: Kelurahan Tarikolot Kecamatan Majalengka</p>
  </section>
);

export default Details;
