import Header from "./components/Header";
import Details from "./components/Details";
import Map from "./components/Map";

const HomeScreen = () => (
  <div className="container mx-auto ">
    <Header />
    <div className="px-5">
      <Details />
      <Map />
    </div>

    <section className="my-10 text-center px-5">
      <p className="text-base md:text-lg text-black mt-4">
        Merupakan suatu kebahagiaan bagi kami apabila, Bapak/Ibu/Saudara/i
        berkenan hadir untuk memberikan doa terbaik untuk putra kami, atas
        kehadirannya kami ucapkan terimakasih.
      </p>
      <p className="text-base md:text-lg text-black  my-5">----------------</p>
      <h2 className="text-xl md:text-3xl font-semibold text-yellow-600">
        Bapak Ivan dan Ibu Melly
      </h2>

      <div className="text-sm mt-20 text-gray-600 border-t border-green-500 pt-5 border-dashed">
        Crafted with love by <a href="https://undangan.eventqiu.com/" className="text-green-500 underline">UndanganDigital</a>
      </div>
    </section>
    <img
      src="https://eventqiu.com/assets/footer_undangan.jpg"
      className="w-full object-contain"
      alt=""
    />
  </div>
);

export default HomeScreen;
