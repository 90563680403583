import React from "react";

const Header = () => (
  <header className="text-center">
    <img
      src="https://eventqiu.com/assets/header_undangan.jpg"
      className="w-full object-contain"
      alt=""
    />
    <img
      src="https://eventqiu.com/assets/hero.png"
      className="w-full object-contain"
      alt=""
    />
    <div className="flex justify-center -mt-10">
      <img
        src="https://eventqiu.com/assets/photo_keanovan.png"
        className="w-[200px] object-contain rounded-full"
        alt=""
      />
    </div>

    <div className="px-5">
      <h1 className="text-xl md:text-5xl  text-yellow-600 mt-5">
        <h5 class="mb-0">Syukuran Khitanan</h5>
      </h1>

      <h1 className="text-2xl md:text-5xl font-bold text-yellow-600 mt-5">
        <h5 class="mb-0">Muhammad Keanovan Ar. Rasyid</h5>
      </h1>
      <p className="text-lg md:text-xl text-black mt-4">
        Kami mengundang Anda untuk menghadiri acara khitanan anak kami.
      </p>
    </div>
  </header>
);

export default Header;
